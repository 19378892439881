<template>
  <div class="editKnowledge">
    <div class="title">
      <div class="ti-left">编辑{{ knowledgeName }}知识库</div>
      <div class="ti-rigth">
        <a-dropdown :trigger="['click']">
          <a-button type="primary" sizi="small"> 上传文件 </a-button>

          <a-menu slot="overlay">
            <a-menu-item key="1" @click="addFormDoc">
              <a-icon type="folder" />从云文档添加
            </a-menu-item>
            <a-menu-item key="2" @click="uploaderFromLocal">
              <a-icon type="global" />本地上传
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <div class="content">
      <!-- 列表 -->
      <template>
        <a-table
          ref="editKnowledge_table"
          :loading="loading"
          :columns="columns"
          :data-source="listData"
          :pagination="false"
          :scroll="{ y: fileListEleMaxHeight || 550 }"
        >
          <template slot="name" slot-scope="text, record">
            <template>
              <h-icon
                class="file-icon-img"
                :type="record.extensionName"
                alt=""
              />
              <span class="name-text click">
                {{ record.userFileName }}.{{ record.extensionName }}</span
              >
            </template>
          </template>

          <template slot="date" slot-scope="text, record">
            <span
              v-if="
                !record.fileFinishTime &&
                record.fileStatus &&
                record.fileStatus !== 4
              "
              >学习中</span
            >

            <span
              style="margin-left: 12px"
              v-if="record.fileStatus === 1 || record.fileStatus === 2"
            >
              <a-spin />
            </span>

            <span v-if="record?.fileFinishTime">
              {{ record?.fileFinishTime }}</span
            >
            <span v-if="record?.fileStatus == 4" style="color: red"
              >上传失败,请检查文件后重新上传</span
            >
          </template>

          <template slot="size" slot-scope="text, record">
            {{ record?.userFileSize }}
          </template>

          <template slot="action" slot-scope="text, record">
            <a-dropdown>
              <a><a-icon type="dash" /></a>
              <a-menu slot="overlay">
                <a-menu-item @click="rename(record)">
                  <a>重命名</a>
                </a-menu-item>
                <a-menu-item @click="preview(record)">
                  <a>预览</a>
                </a-menu-item>
                <a-menu-item @click="remove(record)">
                  <a>移出知识库</a>
                </a-menu-item>
                <a-menu-item @click="down(record)">
                  <a>下载</a>
                </a-menu-item>
                <a-menu-item @click="del(record)">
                  <a>删除</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </a-table>

        <!-- 加载更多提示 -->
        <div
          class="load-more"
          v-if="listData.length < total && scollFlag"
          @click="loadMore"
        >
          加载中
        </div>
        <div
          class="load-more"
          v-if="listData.length >= total && total > 0 && scollFlag"
        >
          没有更多了
        </div>
      </template>
    </div>

    <addFile
      v-if="showAddFile"
      :showAddFile="showAddFile"
      :addFileType="addFileType"
      @closeAddFile="closeAddFile"
      @addFilesFn="addFilesFn"
    />

    <!-- 公共弹窗 -->
    <confirmationDialog
      v-if="showConfirmationDialog"
      :showConfirmationDialog="showConfirmationDialog"
      :ModalText="ModalText"
      :title="dialogTitle"
      :type="dialogType"
      :iptValue="iptValue"
      :maxLength="maxLength"
      @confirmationDialog_Cancel="confirmationDialog_Cancel"
      @confirmationDialog_Ok="confirmationDialog_Ok"
    />
  </div>
</template>

<script>
import confirmationDialog from "@/components/confirmationDialog/index";
import addFile from "@/components/GlobalDocTool/components/addFile/index.vue";
import {
  publicFormatSize,
  publicFormatTime,
  downloadDocFile,
  publicFileView,
} from "@/utils/index";
import { setInterval } from "timers";
export default {
  name: "knowledgeBase",
  components: {
    addFile,
    confirmationDialog,
  },
  data() {
    return {
      scollFlag: false,
      maxLength: 30,
      typeFn: "rename",
      iptValue: "",
      dialogType: "createOrRename",
      dialogTitle: "重命名",
      showConfirmationDialog: false,
      ModalText: "",
      userFileId: "",
      loading: false,
      pollIntervalTimer: null,
      showAddFile: false,
      knowledgeName: "",
      knowledgeId: "",
      addFileType: "editKnowledge",
      fileListEleMaxHeight: 550,
      name: "知识库",
      pageNum: 1,
      total: 0,
      listData: [],
      columns: [
        {
          width: 380,
          title: "名称",
          dataIndex: "fileName",
          // key: 'fileName',
          align: "left",
          ellipsis: true,
          scopedSlots: { customRender: "name" },
        },
        {
          align: "center",
          title: "更新时间",
          scopedSlots: { customRender: "date" },
          key: "id",
          width: 250,
        },
        {
          align: "center",
          title: "文件大小",
          width: 250,
          scopedSlots: { customRender: "size" },
          key: "userFileSize",
        },
        {
          align: "center",
          title: "源文件位置",
          dataIndex: "userFilePath",
          ellipsis: true,

          // scopedSlots: { customRender: "userFilePath" },
          key: "userFilePath",
        },
        // {
        //   title: "Tags",
        //   key: "tags",
        //   dataIndex: "tags",
        //   scopedSlots: { customRender: "tags" },
        // },
        {
          align: "center",
          width: 200,
          title: "操作",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  created() {
    //   this.getKnowlgeBaseList();
    console.log("路由传参query", this.$route.query);
    this.knowledgeName = this.$route.query.knowledgeName;
    this.knowledgeId = this.$route.query.knowledgeId;
    this.getKnowledgeFileList();
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.computedHeightFn();
      }, 500);
    });
    window.addEventListener("resize", this.updateBrowserHeight);

    // 添加滚动事件监听
    const tableBody =
      this.$refs.editKnowledge_table.$el.querySelector(".ant-table-body");
    tableBody.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    if (this.pollIntervalTimer) {
      clearInterval(this.pollIntervalTimer);
      this.pollIntervalTimer = null;
    }
    window.removeEventListener("resize", this.updateBrowserHeight);
  },
  computed: {
    uploadSuccessForKnowledgeBasefromLoaclFlag() {
      return this.$store.state.knowledgeBase
        .uploadSuccessForKnowledgeBasefromLoaclFlag;
    },
    uploadSuccessForKnowledgeBasefromLoaclFileId() {
      return this.$store.state.knowledgeBase
        .uploadSuccessForKnowledgeBasefromLoaclFileId;
    },
  },
  watch: {
    uploadSuccessForKnowledgeBasefromLoaclFlag(newVal) {
      if (newVal) {
        this.addFileToKnowledgeFn([
          this.uploadSuccessForKnowledgeBasefromLoaclFileId,
        ]);
        // this.getKnowledgeFileList();
        setTimeout(() => {
          this.$store.commit(
            "knowledgeBase/set_uploadSuccessForKnowledgeBase",
            false
          );
        }, 500);
      }
    },
  },
  methods: {
    // 刷新当前列表
    refresh(flag) {
      this.total = 0;
      this.pageNum = 1;
      if (flag) {
        this.getKnowledgeFileList(true);
      } else {
        this.getKnowledgeFileList();
      }
    },

    // 计算高度
    computedHeightFn() {
      const star_content_fileContainer =
        document.querySelector(".editKnowledge");
      const star_content_fileContainer_height =
        star_content_fileContainer && star_content_fileContainer.offsetHeight;

      this.$nextTick(() => {
        console.log("总高度是多少--", star_content_fileContainer_height);

        this.fileListEleMaxHeight = star_content_fileContainer_height - 150;
        console.log(
          "编辑知识库滚动的最大高度是多少",
          this.fileListEleMaxHeight
        );
      });
    },

    // 用户改变了浏览器高度
    updateBrowserHeight() {
      this.browserHeight = window.innerHeight;
      this.$nextTick(() => {
        this.computedHeightFn();
      });
    },

    // 从本地上传
    uploaderFromLocal() {
      console.log("触发了本地上传吗？");

      this.$store.commit("upload/SET_UPLOADCLICK", "");
    },

    // 关闭弹窗
    confirmationDialog_Cancel() {
      this.showConfirmationDialog = false;
    },
    // 点击弹窗确认
    confirmationDialog_Ok(val) {
      console.log("正在做什么操作", this.typeFn);
      console.log("输入了什么", val);

      if (this.typeFn == "rename") {
        this.confirmRename(val);
      } else if (this.typeFn == "delKnowledgeFile") {
        this.delFileFn();
      }

      this.confirmationDialog_Cancel();
    },

    // 确认重命名
    async confirmRename(val) {
      this.loading = true;
      const params = {
        id: this.userFileId,
        userFileName: val,
      };
      try {
        const res = await this.$apis.renameFile(params);
        this.$message.success("修改成功");
      } catch (error) {
        console.log("重命名失败", error);
      } finally {
        this.getKnowledgeFileList();
      }
    },

    // 点击添加文件确认按钮
    addFilesFn(selectedFiles) {
      const ids = selectedFiles.map((item) => item.id);
      console.log("准备为知识库添加文件---", ids);
      this.addFileToKnowledgeFn(ids);
      this.closeAddFile();
    },

    // 调用接口添加文件到知识库
    async addFileToKnowledgeFn(ids) {
      const params = {
        knowledgeIds: [this.knowledgeId],
        userFileIds: ids,
      };
      console.log("从云文档添加文件到知识库的参数", params);
      try {
        const res = await this.$apis.addFileToKnowledgeHttp(params);
        console.log("添加文件到知识库", res);
        // 好像就云文档的后端返回状态码code是字符串，其他本来就是数字
        this.$message.success("添加成功");
        // this.getKnowledgeFileList();
        this.refresh();
      } catch (error) {
        console.log("添加文件到知识库--error", error);
      }
    },

    // 关闭添加文件的弹窗
    closeAddFile() {
      this.showAddFile = false;
    },
    // 从云文档添加
    addFormDoc() {
      this.$store.dispatch("docTool/async_setActiveMenu", "editKnowledge");
      setTimeout(() => {
        this.showAddFile = true;
      }, 200);
    },

    // 重命名
    rename(row) {
      this.maxLength = 30;
      this.userFileId = row.userFileId;
      this.dialogTitle = "重命名";
      this.dialogType = "createOrRename";
      this.typeFn = "rename";
      this.iptValue = row.userFileName;
      this.showConfirmationDialog = true;
    },

    // 预览
    preview(record) {
      let temp = {
        actionFileid: record.userFileId,
        actionFileSize: record.userFileSize,
        actionFileFullName: `${record.userFileName}.${record.extensionName}`,
      };
      publicFileView(temp);
    },

    // 移出知识库
    async remove(row) {
      this.loading = true;
      const params = {
        knowledgeId: row.knowledgeId,
        userFileId: row.userFileId,
      };
      console.log("移除知识库文件--params", params);
      try {
        const res = await this.$apis.removeKnowledgeFileHttp(params);
        console.log("移除知识库文件", res);
        this.$message.success("移除成功");
      } catch (error) {
        console.log("移除知识库文件--error", error);
      } finally {
        this.getKnowledgeFileList();
      }
    },

    // 下载
    down(record) {
      console.log("下载--record", record);
      downloadDocFile(record.userFileId);
    },

    // 删除
    del(row) {
      console.log("删除知识库", row);
      this.userFileId = row.userFileId;
      this.typeFn = "delKnowledgeFile";
      this.dialogType = "del";
      this.dialogTitle = "删除知识库文件";
      this.ModalText = `确认删除知识库文件【${row.userFileName}.${row.extensionName}】吗? 一旦操作不可撤回，请谨慎操作`;
      this.showConfirmationDialog = true;
    },

    async delFileFn() {
      this.loading = true;
      const params = {
        userFileId: this.userFileId,
      };
      try {
        const res = await this.$apis.delKnowledgeFileHttp(params);
        console.log("删除用户知识库文件--res", res);
        this.$message.success("删除成功");
      } catch (error) {
        console.log("删除用户知识库文件--error", error);
      } finally {
        this.getKnowledgeFileList();
      }
    },

    // 编辑器知识库
    toEditknowledge(record) {
      //   this.$router.push({
      //     name: "editKnowledge",
      //     query: record,
      //   });
      console.log("编辑--知识库");
    },
    addNewKnowlgeBase() {
      console.log("新建知识库");
    },

    // 检查是否需要轮询
    checkPollingCondition() {
      let allComplete = true; // 假设所有项都已完成
      if (!this.listData?.length) {
        console.log("没有任何文件");
        return;
      }

      // 如果列表为空或者所有项的状态都是 3 或 4，表示完成
      allComplete = this.listData.every(
        (item) => item.fileStatus == 3 || item.fileStatus == 4
      );

      setTimeout(() => {
        if (allComplete) {
          // 所有项都完成，停止轮询
          if (this.pollIntervalTimer) {
            clearInterval(this.pollIntervalTimer);
            this.pollIntervalTimer = null;
          }
          console.log("001-", this.pollIntervalTimer);
        } else {
          console.log("002-");
          // 如果有未完成的项，且当前没有设置轮询，则开始轮询
          if (!this.pollIntervalTimer) {
            console.log("知识库文件列表---启动轮询--allComplete", allComplete);
            // 开始轮询，每隔5秒调用一次
            this.pollIntervalTimer = setInterval(() => {
              console.log("轮询里清除不掉了么", this.pollIntervalTimer);
              this.getKnowledgeFileList(true);
            }, 5000);
          } else {
            clearInterval(this.pollIntervalTimer);
            this.pollIntervalTimer = null;
          }
        }
      }, 1000);
    },

    // 滚动事件处理
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollHeight - scrollTop === clientHeight) {
        this.scollFlag = true; // 显示“已加载完全部数据”消息
        setTimeout(() => {
          this.scollFlag = false; // 1秒后隐藏该消息
        }, 1000);

        if (this.loading || this.listData.length >= this.total) {
          return;
        }

        this.loadMore(); // 当滚动到底部时加载下一页数据
      }
    },

    // 加载更多数据
    loadMore() {
      if (this.loading || this.listData.length >= this.total) {
        return;
      }
      this.pageNum++;
      this.getKnowledgeFileList();
    },

    async getKnowledgeFileList(flag) {
      if (!flag) {
        this.loading = true;
      }
      const params = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: 20,
        knowledgeId: this.knowledgeId,
      };
      try {
        const res = await this.$apis.getKnowledgeFileListHttp(params);
        if (res.code == 200) {
          this.total = res.data.total;

          if (res?.data?.list?.length) {
            let listTemp = res.data.list;
            listTemp.forEach((item) => {
              (item.userFileSize = publicFormatSize(item.userFileSize)),
                (item.fileFinishTime = item.fileFinishTime
                  ? publicFormatTime(item.fileFinishTime)
                  : null);
            });

            // if (this.listData.length >= this.total) {
            //   return;
            // }

            if (this.pageNum == 1) {
              this.listData = listTemp;
            } else {
              this.listData = this.listData.concat(listTemp);
            }

            // this.listData = this.listData.concat(listTemp);
          }

          let allComplete = this.listData.every(
            (item) => item.fileStatus == 3 || item.fileStatus == 4
          );

          if (this.listData.length >= this.total) {
            console.log("所有分页数据已加载完毕");
          }

          if (!allComplete) {
            console.log("当前加载的这部分数据存在未完成文件");
            setTimeout(() => {
              console.log("重新获取一次");
              // this.pageNum = 1;
              // this.getKnowledgeFileList(true);
              this.refresh(true);
            }, 5000);
          } else {
            console.log("完成了-----");
          }

          // this.checkPollingCondition();
        }
      } catch (error) {
        console.log("请求知识库列表error", error);
      } finally {
        this.loading = false;
        // setTimeout(() => {
        //   this.$nextTick(() => {
        //     if (this.listData?.length) {
        //       this.checkPollingCondition();
        //     }
        //   });
        // }, 500);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.editKnowledge {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 0px !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    display: flex;
    font-weight: 600;
    height: 36px;
    color: #3399ff;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    user-select: none;
    padding: 20px;
    padding-right: 40px !important;
    .file-icon-img {
      margin-right: 6px;
    }
  }

  .load-more {
    position: absolute;
    bottom: 8px;
    left: 50%;
    color: #999;
  }
}
</style>
